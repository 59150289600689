import axios from 'axios'
import { getToken } from '../LocalStorage';
import { SERVER_URI } from '../../../config'

export const axiosInstance = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  baseURL: SERVER_URI,
  responseType: "json"
});

const requestHandler = (request) => {
  if (request.headers.isTokenRequired) {
    request.headers.common['x-access-token'] = getToken('auth')
  }
  delete request.headers.isTokenRequired
  return request
}

const errorHandler = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 400:
      case 401:
      case 406:
      case 409:
      case 422:
        throw Error(err.response.data.message);
      case 404:
        throw Error("Page Not Found");
      case 500:
        throw Error("Service Not Available");
      default:
        throw Error("Sorry Something Went Wrong");
    }
  }
  throw err
}
axiosInstance.interceptors.request.use((request) => requestHandler(request))
axiosInstance.interceptors.response.use(
  response => response
  , error => errorHandler(error)
)

