import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TextField, Button, Container, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import API from '../Common/Network/API';
import { makeStyles } from '@material-ui/core/styles';
import '../Common/Css/style.css'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux'
import { isLoading, isLoaded } from '../Common/Actions/loading'
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Terminal from 'terminal-in-react';

const label = { inputProps: { 'aria-label': 'Switch demo' } };



const useStyles = makeStyles((theme) => ({
    modalForm: {
        padding: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    space: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export function Data(props) {


    return (
        <div>
            {props.status ?
                <div style={{ backgroundColor: '#95DEB0', borderRadius: '5px', width: '100px', textAlign: 'center' }}>
                    <p style={{ padding: '5px' }}>UP</p>
                </div>
                :
                <div style={{ backgroundColor: '#dd9696', borderRadius: '5px', width: '100px', textAlign: 'center' }}>
                    <p style={{ padding: '5px' }}>Down</p>
                </div>}
        </div>
    )
}

export default function Logs() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({})
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const [rows, setRows] = useState([])
    const [openEdit, setOpenEdit] = useState(false)
    const [update, setUpdate] = useState({})
    const [subOpen, setSubOpen] = useState(false)
    const [email, setEmail] = useState()
    const [loader, setLoader] = useState(false)
    const [appName, setAppName] = useState(null)
    const [logView, setLogView] = useState(false)
    const [logData, setLogData] = useState(null)
    const [logFilterData, setLogFilterData] = useState(null)

    const dispatch = useDispatch()

    const loaderStatus = useSelector(state => state.load.status)


    useEffect(() => {
        if (rows.length > 0) {
            $(document).ready(function () {
                window.$('#serverTable').DataTable({
                    bDestroy: true,
                    "order": [[0, 'desc']]
                });
            });
        }
    }, [rows, loaderStatus])


    useEffect(() => {
        getServerData()
    }, [])


    async function getServerData() {
        dispatch(isLoading())
        setRows([])
        await API.get("/logsApi/get", {}, {}, true)
            .then(result => {
                setRows(result)
                dispatch(isLoaded())
            })
            .catch(err => {
                console.log(err)
                dispatch(isLoaded())
            })
        dispatch(isLoaded())
    }


    const handleOpen = () => {
        setOpen(true);
    };

    const handleSubOpen = () => {
        setSubOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEdit(false);
        setSubOpen(false)
    };
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeUpdate = (e) => {
        setUpdate({
            ...update,
            [e.target.name]: e.target.value
        })
    }
    const handleEdit = (name, url, type, id, user, password, filePath) => {
        setOpenEdit(true)
        setUpdate({
            id: id,
            serverName: name,
            serverUrl: url,
            type: type,
            user: user,
            password: password,
            filePath: filePath
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data.serverName || !data.serverUrl || !data.type || !data.user || !data.password || !data.filePath) {
            toast.info("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/logsApi/add", {
                    name: data.serverName,
                    url: data.serverUrl,
                    type: data.type,
                    user: data.user,
                    password: data.password,
                    filePath: data.filePath
                }, {}, true);
                handleClose()
                getServerData()
                toast.info(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!update.serverName || !update.serverUrl || !update.type || !update.user || !update.password || !update.filePath) {
            toast.info("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/logsApi/update", {
                    linkId: update.id,
                    name: update.serverName,
                    url: update.serverUrl,
                    type: update.type,
                    user: update.user,
                    password: update.password,
                    filePath: update.filePath
                }, {}, true);
                handleClose()
                getServerData()
                toast.info(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }

    const handleDelete = async () => {
        if (!update.id) {
            setError("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/logsApi/remove", {
                    id: update.id,
                }, {}, true);
                handleClose()
                getServerData()
                toast.info(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }

    const handleSubScribe = async (e) => {
        e.preventDefault();
        if (!email) {
            setError("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/authApi/subScription", {
                    email: email,
                }, {}, true);
                handleClose()
                setSuccess(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }



    const handleLog = async (name, url, type, id, user, password, filePath) => {
        setLoader(true)
        setAppName(name)
        try {
            const response = await API.post("/logsApi/handleLogs", {
                data: { url: url, user: user, password: password, filePath: filePath, type, id },
            }, {}, true);
            setLoader(false)
            setLogData(response.message)
            setLogFilterData(response.message)
        }
        catch (error) {
            setError(error.message)
        }
        setLoader(false)
        setLogView(true)
    }

    const handleSearch = (e) => {
        // if (e.target.value.length !== undefined) {
        //     setLogData(logFilterData.filter((y) => {
        //         return y.data?.toLowerCase().includes(e.target.value?.toLowerCase())
        //     })
        //     )

        // } else {
        //     setLogData(logFilterData)
        // }
    }


    return (
        <Container>
            <ToastContainer />
            <div style={{ marginTop: '12%' }}>
                <Button display='flex' onClick={handleOpen}>
                    <AddBoxIcon />
                    Add Log

                </Button>
                <Button style={{ float: 'right' }} variant="contained" onClick={handleSubOpen}>SubScribe</Button>
                <div>
                    {success ?
                        <Alert severity="success">{success}</Alert>
                        :
                        null}
                </div>
                <br />
                <div>
                    {
                        loaderStatus && <center>Loading...</center>
                    }
                    {
                        !loaderStatus &&
                        <div>
                            <table id="serverTable" className="table table-striped table-bordered" >
                                <thead>
                                    <tr>
                                        <th style={{ display: 'none' }}>id</th>
                                        <th>Log Name</th>
                                        <th>IP Address</th>
                                        <th>Type</th>
                                        <th>Edit</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {

                                        rows?.map(row => {
                                            return (
                                                <tr key={row?._id}>
                                                    <td style={{ display: 'none' }}>{row?._id}</td>
                                                    <td>{row?.logName}</td>
                                                    <td>{row?.url} <a href={(/https:/.test(row?.url)) || (/http:/.test(row?.url)) ? row?.url : `http://${row?.url}`} without="true" rel="noopener noreferrer" target="_blank"><OpenInNewIcon /></a></td>
                                                    <td>{row?.type}</td>
                                                    <td><EditIcon onClick={() => handleEdit(row?.logName, row?.url, row?.type, row?._id, row?.user, row?.password, row?.filePath)} /></td>
                                                    <td>
                                                        <Button onClick={() => handleLog(row?.logName, row?.url, row?.type, row?._id, row?.user, row?.password, row?.filePath)} variant="contained" type="button" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" width="20px" alt="" /> : "View"}</Button>
                                                        {" "}{appName === row.logName && loader ? <img style={{ width: '20px' }} src='./assets/img/loader.gif' alt="Loading..." /> : ""}
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }

                                </tbody>
                            </table>
                        </div>
                    }

                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <form onSubmit={e => handleSubmit(e)} noValidate autoComplete="off" className={classes.space}>
                                <TextField variant="filled" label="Log Name*" name="serverName" onChange={e => handleChange(e)} color="primary" />
                                <br />
                                <TextField variant="filled" label="URL*" name="serverUrl" onChange={e => handleChange(e)} color="primary" />
                                <br />
                                <TextField variant="filled" label="User*" name="user" onChange={e => handleChange(e)} color="primary" />
                                <br />
                                <TextField type="password" variant="filled" label="Password*" name="password" onChange={e => handleChange(e)} color="primary" />
                                <br />
                                <TextField type="text" variant="filled" label="File Path*" name="filePath" onChange={e => handleChange(e)} color="primary" />
                                <br />
                                <div>
                                    <select className='form-control' name="type" onChange={e => handleChange(e)} color="primary">
                                        <option value="">Select One</option>
                                        <option value="vm">Virtual Machine</option>
                                        <option value="server">Server</option>
                                    </select>
                                </div>
                                <div className={classes.space}>
                                    <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Add"}</Button>
                                    {
                                        !loaderStatus ?
                                            <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                            :
                                            null
                                    }
                                </div>
                                {/* {
                                    error ?
                                        <Alert severity="warning">{error}</Alert>
                                        :
                                        ""
                                } */}
                            </form>
                        </div>
                    </Fade>
                </Modal>
                {/* update Modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openEdit}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openEdit}>
                        <div className={classes.paper}>
                            <form onSubmit={e => handleUpdate(e)} noValidate autoComplete="off" className={classes.space}>
                                <TextField variant="filled" value={update.serverName} name="serverName" onChange={e => handleChangeUpdate(e)} color="primary" />
                                <br />
                                <TextField variant="filled" value={update.serverUrl} name="serverUrl" onChange={e => handleChangeUpdate(e)} color="primary" />
                                <br />
                                <TextField variant="filled" value={update.user} label="User*" name="user" onChange={e => handleChangeUpdate(e)} color="primary" />
                                <br />
                                <TextField type="password" value={update.password} variant="filled" label="Password*" name="password" onChange={e => handleChangeUpdate(e)} color="primary" />
                                <br />
                                <TextField type="text" value={update.filePath} variant="filled" label="File Path*" name="filePath" onChange={e => handleChangeUpdate(e)} color="primary" />
                                <br />
                                <div>
                                    <select className='form-control' value={update.type} name="type" onChange={e => handleChangeUpdate(e)} color="primary">
                                        <option value="">Select One</option>
                                        <option value="vm">Virtual Machine</option>
                                        <option value="server">Server</option>
                                    </select>
                                </div>
                                <div className={classes.space}>
                                    <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" width="20px" alt="" /> : "Update"}</Button>
                                    <Button variant="contained" type="button" onClick={() => handleDelete()} color="secondary" disabled={loaderStatus ? true : false}> Remove</Button>
                                    {
                                        !loaderStatus ?
                                            <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                            :
                                            null
                                    }
                                </div>
                                {/* {
                                    error ?
                                        <Alert severity="warning">{error}</Alert>
                                        :
                                        ""
                                } */}
                            </form>
                        </div>
                    </Fade>
                </Modal>
                {/* subscription Modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={subOpen}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={subOpen}>
                        <div className={classes.paper}>
                            <form onSubmit={e => handleSubScribe(e)} noValidate autoComplete="off" className={classes.space}>
                                <TextField variant="filled" name="email" label="Email*" onChange={e => setEmail(e.target.value)} color="primary" />
                                <div className={classes.space}>
                                    <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" width="20px" alt="" /> : "Add"}</Button>
                                    {
                                        !loaderStatus ?
                                            <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                            :
                                            null
                                    }
                                </div>
                                {
                                    error ?
                                        <Alert severity="warning">{error}</Alert>
                                        :
                                        ""
                                }
                            </form>
                        </div>
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    // className={classes.modal}
                    open={logView}
                    onClose={() => setLogView(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={logView}>
                        <div style={{
                            position: 'absolute',
                            marginTop: '80px',
                            marginLeft: '5%',
                            width: '90%',
                            backgroundColor: 'white'
                        }}>

                            {/* <div style={{ marginLeft: '20px', marginTop: '10px', width: '50%' }}>
                                <label>Search</label>
                                <input type="search" className='form-control' onChange={(e) => handleSearch(e)} />
                            </div> */}
                            <div>
                                <button className="btn" style={{ float: 'right' }} type="button" onClick={() => setLogView(false)}>Close</button>
                            </div>
                            <div id="terminal-view" style={{ overflow: 'auto', height: '600px', paddingBottom: '10px' }}>
                                {
                                    !loaderStatus ?
                                        <div >
                                            <Terminal

                                                hideTopBar={false}
                                                startState='maximised'
                                                color='black'
                                                backgroundColor='white'
                                                barColor='white'
                                                style={{ fontWeight: "bold", fontSize: "1em", }}
                                                // commands={{
                                                //     'open-google': () => window.open('https://www.google.com/', '_blank'),
                                                //     showmsg: showMsg,
                                                //     popup: () => alert('Terminal in React')
                                                // }}
                                                // descriptions={{
                                                //     'open-google': 'opens google.com',
                                                //     showmsg: 'shows a message',
                                                //     alert: 'alert', popup: 'alert'
                                                // }}
                                                msg={logData}

                                            />
                                        </div>
                                        :
                                        <center style={{ marginTop: '200px' }}>
                                            Loading...
                                        </center>

                                }
                            </div>

                        </div>
                    </Fade>
                </Modal>
            </div>
        </Container >
    );
}