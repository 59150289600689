import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TopNav from '../Utility/TopNav';
import Users from './users';
import Subscriptions from './subscriptions';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    <div>{children}</div>
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


export default function Settings() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <TopNav />
            <div className={classes.root} style={{ marginTop: '100px' }}>
                <AppBar style={{ marginTop: '55px', background: '#FAFAFA' }}>
                    <Tabs value={value} onChange={handleChange} style={{ color: 'blue' }} aria-label="simple tabs example">
                        <Tab label="Users" {...a11yProps(0)} />
                        <Tab label="Subscribers" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                    <Users />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Subscriptions />
                </TabPanel>
            </div>
        </div >
    );
}



