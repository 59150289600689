import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';
import API from '../Common/Network/API';
import { Redirect } from 'react-router-dom';
import { setToken } from '../Common/LocalStorage';
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentUser } from '../../Components/Common/Actions'
import jwt_decode from 'jwt-decode'
import { isLoading, isLoaded } from '../Common/Actions/loading'
import Footer from '../Common/Footer';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://novisync.com/">
        Novisync
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

}));


export default function SignIn() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [data, setData] = useState({})
  const [error, setError] = useState()
  const [redirect, setRedirect] = useState(false)

  const loaderStatus = useSelector(state => state.load.status)

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!data.email || !data.password) {
      if (!data.email) {
        setError("Please Enter Username")
      }
      if (!data.password) {
        setError("Please Enter Password")
      }
    } else {
      setError("")
      dispatch(isLoading())
      try {
        const response = await API.post("/authApi/login", {
          email: data.email,
          password: data.password,
        }, {}, false);
        setToken('auth', response.token)
        const tokenDetails = jwt_decode(response.token);
        dispatch(setCurrentUser(tokenDetails));//store author information
        setRedirect(true)
        dispatch(isLoaded())
      }
      catch (error) {
        setError(error.message)
      }
      dispatch(isLoaded())
    }
  }

  if (redirect) {
    return <Redirect to="/home" />
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src="/assets/img/novisync_new.png" width="80" alt="" />
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={(e) => handleSubmit(e)} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
            onChange={e => handleChange(e)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => handleChange(e)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {
              loaderStatus ?
                <img src="./assets/img/loader.gif" width="20px" alt="" />
                :
                "Sign In"
            }
          </Button>
          {
            error ?
              <Alert severity="warning">{error}</Alert>
              :
              ""
          }
        </form>
      </div>
      <Box mt={8}>
      <Footer />
      </Box>
    </Container>
  );
}