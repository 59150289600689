import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TextField, Button, Container } from '@material-ui/core';
import API from '../Common/Network/API';
import { makeStyles } from '@material-ui/core/styles';
import '../Common/Css/style.css'

import { useDispatch, useSelector } from 'react-redux'
import { isLoading, isLoaded } from '../Common/Actions/loading'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'
import $ from 'jquery'

const useStyles = makeStyles((theme) => ({
    modalForm: {
        padding: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    space: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root: {
        maxWidth: 340
    },

    historyRoot: {
        display: 'flex',

    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 200,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    modalRight: {
        display: 'flex',
    },
    paperRight: {
        position: 'absolute',
        right: '10px',
        width: '400px',
        height: '100vh',
        backgroundColor: '#EFEFEF',
        border: '2px soli#000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const customSelectStyle = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
        color: state.isSelected ? "black" : "black"
    }),
}




export default function Subscriptions() {
    const classes = useStyles();
    const [rows, setRows] = useState([])
    const [subOpen, setSubOpen] = useState(false)
    const [email, setEmail] = useState()
    const [selectedApp, setSelectedApp] = useState("all")
    const [subscriberList, setSubscribersList] = useState([])
    const [value, setValue] = useState(0);

    const dispatch = useDispatch()
    const loaderStatus = useSelector(state => state.load.status)

    useEffect(() => {
        getData()
        getSubscribeList()
        // eslint-disable-next-line
    }, [value])

    useEffect(() => {
        if (rows.length > 0) {
            $(document).ready(function () {
                window.$('#rowsTable').DataTable();
            });
        }

        //eslint-disable-next-line
    }, [rows])

    async function getSubscribeList() {
        dispatch(isLoading())
        setSubscribersList([])
        try {
            const response = await API.get("/authApi/getSubscriptionList", {}, {}, true);
            setSubscribersList(response)
            dispatch(isLoaded())
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(isLoaded())
        }
    }

    async function getData() {
        dispatch(isLoading())
        setRows([])
        try {
            const response = await API.post("/urlApi/get", {
                value: value
            }, {}, true);
            setRows(response)
            dispatch(isLoaded())
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(isLoaded())
        }
    }

    const handleSubOpen = () => {
        setSubOpen(true);
    };

    const handleClose = () => {
        setSubOpen(false)
    };

    const validateEmail = (emailField) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(emailField) == false) {
            return false;
        }
        return true;
    }

    const handleSubScribe = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.error("Please Fill Required Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            if (validateEmail(email)) {
                dispatch(isLoading())
                try {
                    const response = await API.post("/authApi/subScription", {
                        email: email,
                        application: selectedApp.value || 'all',
                        name: selectedApp.label || 'all'
                    }, {}, true);
                    handleClose()
                    // setSuccess(response.message)
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    dispatch(isLoaded())
                    getSubscribeList()
                }
                catch (error) {
                    toast.error(error.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
                dispatch(isLoaded())
            } else {
                toast.error("Please enter valid email", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    const handleUnsubscribe = async (email) => {
        dispatch(isLoading())
        try {
            await API.get(`/authApi/unSubscribe?email=${email}`, {}, {}, true);
            getSubscribeList()
            dispatch(isLoaded())
            toast.error(`${email} Unsubscribed successfully`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(isLoaded())
        }
    }


    const defaultOptions = { value: 'all', label: 'All' }
    const newOptions = rows?.map(x => {
        return {
            value: x.result._id, label: x.result.name
        }
    })
    const options = [...newOptions, defaultOptions].reverse()

    const handleSearch = (value) => {

    }

    return (
        <div>
            <Container>
                <ToastContainer />
                <br />
                <br />
                {/* <div style={{ marginTop: '3%', textAlign: 'right' }}>
                    <TextField
                        type="search"
                        variant="outlined"
                        label="Search"
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div> */}
                <div style={{ marginTop: '50px' }}>
                    <Button display='flex' onClick={handleSubOpen}>
                        <AddBoxIcon />
                        Add Subscriber
                    </Button>
                    <br />
                    <br />

                    <div>
                        <center style={{ border: 'solid', padding: '10px', margin: '10px' }}>
                            <table id="rowsTable" className="table table-striped table-bordered" >
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Application </th>
                                        <th>Created Date</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        subscriberList?.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{row.email}</td>
                                                    <td>{row.name}</td>
                                                    <td>{moment(row.createdAt).format('lll')}</td>
                                                    <td>
                                                        <Button color="secondary" variant="contained" style={{ marginRight: '5px' }} size="small" onClick={() => handleUnsubscribe(row.email)} className={classes.margin}>
                                                            Unsubscribe
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </center>
                    </div>


                    <br /><br />
                    {/* subscription Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={subOpen}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={subOpen}>
                            <div className={classes.paper}>
                                <form onSubmit={e => handleSubScribe(e)} noValidate autoComplete="off" className={classes.space}>
                                    <Select
                                        styles={customSelectStyle}
                                        options={options}
                                        placeholder="Select Application"
                                        onChange={e => setSelectedApp(e)}
                                    />
                                    <TextField style={{ zIndex: 1 }} variant="filled" name="email" label="Email" type="email" onChange={e => setEmail(e.target.value)} color="primary" required />
                                    <div className={classes.space}>
                                        <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Add"}</Button>
                                        {
                                            !loaderStatus ?
                                                <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                                :
                                                null
                                        }
                                    </div>

                                </form>
                            </div>
                        </Fade>
                    </Modal>
                </div >
            </Container >
        </div >
    );
}