import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TextField, Button, Container } from '@material-ui/core';
import API from '../Common/Network/API';
import { makeStyles } from '@material-ui/core/styles';
import '../Common/Css/style.css'

import { useDispatch, useSelector } from 'react-redux'
import { isLoading, isLoaded } from '../Common/Actions/loading'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery'

const useStyles = makeStyles((theme) => ({
    modalForm: {
        padding: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    space: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root: {
        maxWidth: 340
    },

    historyRoot: {
        display: 'flex',

    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 200,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    modalRight: {
        display: 'flex',
    },
    paperRight: {
        position: 'absolute',
        right: '10px',
        width: '400px',
        height: '100vh',
        backgroundColor: '#EFEFEF',
        border: '2px soli#000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const customSelectStyle = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
        color: state.isSelected ? "black" : "black"
    }),
}




export default function Users() {
    const classes = useStyles();
    const [rows, setRows] = useState([])
    const [subOpen, setSubOpen] = useState(false)
    const [data, setData] = useState({})
    const [openEdit, setOpenEdit] = useState(false)
    const [updateData, setUpdateData] = useState({})

    const dispatch = useDispatch()
    const loaderStatus = useSelector(state => state.load.status)

    useEffect(() => {
        getUsersData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (rows.length > 0) {
            $(document).ready(function () {
                window.$('#rowsTable').DataTable();
            });
        }

        //eslint-disable-next-line
    }, [rows])

    async function getUsersData() {
        dispatch(isLoading())
        setRows([])
        try {
            const response = await API.get("/authApi/getUserData", {}, {}, true);
            setRows(response)
            dispatch(isLoaded())
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(isLoaded())
        }
    }

    const handleSubOpen = () => {
        setSubOpen(true);
    };

    const handleClose = () => {
        setSubOpen(false)
        setOpenEdit(false)
    };
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleSearch = (value) => {

    }

    const validateEmail = (emailField) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(emailField) == false) {
            return false;
        }
        return true;
    }

    const handleUser = async (e) => {
        e.preventDefault();
        if (!data.email) {
            toast.error("Please Fill Required Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            if (validateEmail(data.email)) {
                dispatch(isLoading())
                try {
                    const response = await API.post("/authApi/register", {
                        email: data.email,
                        password: data.password,
                        role: data.role
                    }, {}, true);
                    handleClose()
                    // setSuccess(response.message)
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    dispatch(isLoaded())
                    getUsersData()
                }
                catch (error) {
                    toast.error(error.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
                dispatch(isLoaded())
            } else {
                toast.error("Please enter valid email", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    const handleUpdate = (data) => {
        setOpenEdit(true)
        setUpdateData({
            email: data.email,
            role: data.role,
            id: data._id
        })
    }

    const handleDelete = async (email) => {
        dispatch(isLoading())
        try {
            await API.get(`/authApi/removeUser?email=${email}`, {}, {}, true);
            getUsersData()
            dispatch(isLoaded())
            toast.error(`${email} Removed successfully`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(isLoaded())
        }
    }

    const handleUpdateChange = (e) => {
        setUpdateData({
            ...updateData,
            [e.target.name]: e.target.value
        })
    }

    const handleUpdateUser = async (e) => {
        e.preventDefault()
        if (!updateData.email) {
            toast.error("Please Fill Required Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            if (validateEmail(updateData.email)) {
                dispatch(isLoading())
                try {
                    const response = await API.post("/authApi/updateUser", {
                        id: updateData.id,
                        email: updateData.email,
                        role: updateData.role
                    }, {}, true);
                    handleClose()
                    // setSuccess(response.message)
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    dispatch(isLoaded())
                    getUsersData()
                }
                catch (error) {
                    toast.error(error.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
                dispatch(isLoaded())
            } else {
                toast.error("Please enter valid email", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }

    return (
        <div>
            <Container>
                <ToastContainer />
                <br />
                <br />
                {/* <div style={{ marginTop: '3%', textAlign: 'right' }}>
                    <TextField
                        type="search"
                        variant="outlined"
                        label="Search"
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </div> */}
                <div style={{ marginTop: '50px' }}>
                    <Button display='flex' onClick={handleSubOpen}>
                        <AddBoxIcon />
                        Add User
                    </Button>
                    <br />
                    <br />

                    <div>
                        <center style={{ border: 'solid', padding: '10px', margin: '10px' }}>
                            <table id="rowsTable" className="table table-striped table-bordered" >
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Created Date</th>
                                        <th>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rows?.map((row, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{row.email}</td>
                                                    <td>{row.role}</td>
                                                    <td>{moment(row.createdAt).format('lll')}</td>
                                                    <td>
                                                        <Button color="primary" variant="contained" style={{ marginRight: '5px' }} size="small" onClick={() => handleUpdate(row)} className={classes.margin}>
                                                            Update
                                                        </Button>
                                                        <Button color="secondary" variant="contained" style={{ marginRight: '5px' }} size="small" onClick={() => handleDelete(row.email)} className={classes.margin}>
                                                            Delete
                                                        </Button>

                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            Note* : If user deleted subscription alerts also removed
                        </center>
                    </div>


                    <br /><br />
                    {/* User Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={subOpen}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={subOpen}>
                            <div className={classes.paper}>
                                <form onSubmit={e => handleUser(e)} noValidate autoComplete="off" className={classes.space}>
                                    <TextField style={{ zIndex: 1 }} variant="filled" name="email" label="Email" type="email" onChange={e => handleChange(e)} color="primary" required />
                                    <br />
                                    <TextField style={{ zIndex: 1 }} variant="filled" name="password" label="Password" type="password" onChange={e => handleChange(e)} color="primary" required />
                                    <br />
                                    <TextField style={{ zIndex: 1 }} variant="filled" name="confirmPassword" label="Confirm Password" type="password" onChange={e => handleChange(e)} color="primary" required />
                                    <br />
                                    <select name="role" onChange={e => handleChange(e)}>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </select>
                                    <br />
                                    <div className={classes.space}>
                                        <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Add"}</Button>
                                        {
                                            !loaderStatus ?
                                                <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                                :
                                                null
                                        }
                                    </div>

                                </form>
                            </div>
                        </Fade>
                    </Modal>
                    {/* Update Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openEdit}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openEdit}>
                            <div className={classes.paper}>
                                <form onSubmit={e => handleUpdateUser(e)} noValidate autoComplete="off" className={classes.space}>
                                    <TextField style={{ zIndex: 1 }} value={updateData.email} variant="filled" name="email" label="Email" type="email" onChange={e => handleUpdateChange(e)} color="primary" required />
                                    <br />
                                    <select name="role" onChange={e => handleUpdateChange(e)}>
                                        <option value={updateData.role}>{updateData.role}</option>
                                        <option value="user">User</option>
                                        <option value="admin">Admin</option>
                                    </select>
                                    <br />
                                    <div className={classes.space}>
                                        <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Update"}</Button>
                                        {
                                            !loaderStatus ?
                                                <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                                :
                                                null
                                        }

                                    </div>
                                </form>
                            </div>
                        </Fade>
                    </Modal>
                </div >
            </Container >
        </div >
    );
}