import React from 'react';
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import SignIn from './Components/Auth/SignIn'
import { getToken } from './Components/Common/LocalStorage';
import Home from './Components/Home';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { setCurrentUser } from './Components/Common/Actions';
import store from './Components/Common/Store';
import Settings from './Components/Settings/index'

function App() {

  const history = useHistory()

  if (getToken('auth')) {
    const tokenDetails = jwt_decode(getToken('auth'));
    store.dispatch(setCurrentUser(tokenDetails));
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (tokenDetails.exp < currentTime) {
      localStorage.removeItem('auth');
      // Remove auth header for future requests
      delete axios.defaults.headers.common['x-access-token'];
      // Set current user to empty object {} which will set isAuthenticated to false
      store.dispatch(setCurrentUser({}));
      history.push('/')
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" component={SignIn} exact />
        <Route path="/home" component={Home} exact />
        <Route path="/settings" component={Settings} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
