import React from 'react'
import env from "react-dotenv";
export default function Footer() {
    return (
        <div className='footer'>
            <p style={{ fontSize: '10px', margin: '0px' }}>
                Build Version {env.VERSION} Date {env.BUILD_DATE}
            </p>
            <p style={{ fontSize: '10px' }}>
                © {new Date().getFullYear()} Novisync Inc.All Rights Reserved
            </p>
        </div>
    )
}