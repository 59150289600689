import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TextField, Button, Container, Grid, AppBar, Tabs, Tab, Checkbox } from '@material-ui/core';
import API from '../Common/Network/API';
import { makeStyles } from '@material-ui/core/styles';
import '../Common/Css/style.css'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux'
import { isLoading, isLoaded } from '../Common/Actions/loading'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import TocIcon from '@material-ui/icons/Toc';
import GridOnIcon from '@material-ui/icons/GridOn';
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CSVLink } from "react-csv";
import $ from 'jquery'
import _ from 'lodash'
import Select from 'react-select'

const useStyles = makeStyles((theme) => ({
    modalForm: {
        padding: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    space: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    root: {
        maxWidth: 340
    },

    historyRoot: {
        display: 'flex',

    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 200,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    modalRight: {
        display: 'flex',
    },
    paperRight: {
        position: 'absolute',
        right: '10px',
        width: '400px',
        height: '100vh',
        backgroundColor: '#EFEFEF',
        border: '2px soli#000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

const customSelectStyle = {
    menu: provided => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#FFFFFF" : "#FFFFFF",
        color: state.isSelected ? "black" : "black"
    }),
}




export default function Applications() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({})
    const [rows, setRows] = useState([])
    const [openEdit, setOpenEdit] = useState(false)
    const [openView, setOpenView] = useState(false)
    const [update, setUpdate] = useState({})
    const [subOpen, setSubOpen] = useState(false)
    const [email, setEmail] = useState()
    const [tableView, setTableView] = useState(false)
    const [showMoreTable, setShowMoreTable] = useState(false)
    const [appLogs, setAppLogs] = useState([])
    const [appName, setAppName] = useState()
    const [value, setValue] = useState(0);
    const [onDemand, setOnDemand] = useState("")
    const [localTimeZone, setLocalTimeZone] = useState("")
    const [applicationLogs, setApplicationLogs] = useState()
    const [search, setSearch] = useState()
    const [selectedApp, setSelectedApp] = useState("all")

    useEffect(() => {
        setLocalTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }, [])

    const handleSubMenuChange = (event, newValue) => {
        setValue(newValue);
    };

    const dispatch = useDispatch()

    const loaderStatus = useSelector(state => state.load.status)

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [value])


    useEffect(() => {
        if (appLogs.length > 0) {
            $(document).ready(function () {
                window.$('#serverTable').DataTable();
            });
        }
    }, [appLogs])

    useEffect(() => {
        if (rows.length > 0) {
            $(document).ready(function () {
                window.$('#rowsTable').DataTable();
            });
        }

        //eslint-disable-next-line
    }, [tableView])



    async function getData() {
        dispatch(isLoading())
        setRows([])
        setTableView(false)
        try {
            const response = await API.post("/urlApi/get", {
                value: value
            }, {}, true);
            setRows(response)
            dispatch(isLoaded())
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(isLoaded())
        }
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleViewOpen = (name, url, applicationPath, sourceIp, sourcePassword, sourceUser, applicationStack, id) => {
        setOpenView(true)
        setUpdate({
            id: id,
            appName: name,
            appUrl: url,
            applicationPath: applicationPath,
            sourceIp: sourceIp,
            sourceUser: sourceUser,
            sourcePassword: sourcePassword,
            applicationStack: applicationStack
        })
    }

    const handleSubOpen = () => {
        setSubOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEdit(false);
        setSubOpen(false)
        setShowMoreTable(false)
        setOpenView(false)
    };
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeUpdate = (e) => {
        setUpdate({
            ...update,
            [e.target.name]: e.target.value
        })
    }
    const handleEdit = (name, url, applicationPath, sourceIp, sourcePassword, sourceUser, applicationStack, id) => {
        setOpenEdit(true)
        setUpdate({
            id: id,
            appName: name,
            appUrl: url,
            applicationPath: applicationPath,
            sourceIp: sourceIp,
            sourceUser: sourceUser,
            sourcePassword: sourcePassword,
            applicationStack: applicationStack
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data.appName || !data.appUrl || onDemand === "") {
            toast.warning("Please Fill Required Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            dispatch(isLoading())
            try {
                const response = await API.post("/urlApi/add", {
                    name: data.appName,
                    url: data.appUrl,
                    applicationPath: data.applicationPath,
                    sourceIp: data.sourceIp,
                    sourceUser: data.sourceUser,
                    sourcePassword: data.sourcePassword,
                    applicationStack: data.applicationStack,
                    onDemand: onDemand
                }, {}, true);
                getData()
                setOnDemand("")
                handleClose()
                dispatch(isLoaded())
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setSuccess(response.message)
            }
            catch (error) {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(isLoaded())
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!update.appName || !update.appUrl || onDemand === "") {
            toast.warning("Please Fill Required Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            dispatch(isLoading())
            try {
                const response = await API.post("/urlApi/update", {
                    linkId: update.id,
                    name: update.appName,
                    url: update.appUrl,
                    applicationPath: update.applicationPath,
                    sourceIp: update.sourceIp,
                    sourceUser: update.sourceUser,
                    sourcePassword: update.sourcePassword,
                    applicationStack: update.applicationStack,
                    onDemand: onDemand
                }, {}, true);
                getData()
                handleClose()
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // setSuccess(response.message)
                dispatch(isLoaded())
                setOnDemand("")
            }
            catch (error) {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(isLoaded())
        }
    }

    const handleDelete = async () => {
        if (!update.id) {

            toast.error("Please Fill Required Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            dispatch(isLoading())
            try {
                const response = await API.post("/urlApi/remove", {
                    id: update.id,
                }, {}, true);
                handleClose()
                // setSuccess(response.message)
                getData()
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                dispatch(isLoaded())
            }
            catch (error) {
                toast.error(error.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }
            dispatch(isLoaded())
        }
    }

    const validateEmail = (emailField) => {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(emailField) == false) {
            return false;
        }
        return true;
    }

    const handleSubScribe = async (e) => {
        e.preventDefault();
        if (!email) {
            toast.error("Please Fill Required Fields", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            if (validateEmail(email)) {
                dispatch(isLoading())
                try {
                    const response = await API.post("/authApi/subScription", {
                        email: email,
                        application: selectedApp.value || 'all',
                        name: selectedApp.label || 'all'
                    }, {}, true);
                    handleClose()
                    // setSuccess(response.message)
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    dispatch(isLoaded())
                }
                catch (error) {
                    toast.error(error.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
                dispatch(isLoaded())
            } else {
                toast.error("Please enter valid email", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }


    const handleMoreInfo = async (data, name) => {
        dispatch(isLoading())
        setShowMoreTable(true)
        try {
            const response = await API.post("/urlApi/getApplicationLogs", { data }, {}, true);
            setAppLogs(response)
            setAppName(name)
            dispatch(isLoaded())
        }
        catch (error) {
            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(isLoaded())
        }

    }


    var moreInfoObjLogs = appLogs && appLogs.map((row) => {
        return {
            'name':
                row.host.name,
            'lastUpdated': moment(row.createdAt.toLocaleString('en-US', { timeZone: localTimeZone })).format('LLL'),
            'status':
                row.status === "true" ?
                    "Up"
                    :
                    "Down"
            ,
            'action': row.status === "false" ? row.log : "--"
        }

    })

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    useEffect(() => {


        var objects = appLogs.map(item => {
            return {
                'name': item.host.name,
                'createdAt': moment(item.createdAt).format('LLL'),
                'status': item.status,
                'log': item.log
            }
        })
        setApplicationLogs(_.reject(objects, function (object, i) {
            return i > 0 && objects[i - 1].status === object.status;
        }));

    }, [appLogs])




    const getApplicationList = () => {
        return (search?.length ? rows.filter(item => {
            return (item?.result?.name.toLowerCase()?.includes(search.toLowerCase())
                || item?.result?.url.toLowerCase()?.includes(search.toLowerCase())
                || (item?.result?.applicationStack !== undefined && item?.result?.applicationStack.toLowerCase()?.includes(search.toLowerCase()))
            )
        }) : rows)
    }


    const defaultOptions = { value: 'all', label: 'All' }
    const newOptions = rows?.map(x => {
        return {
            value: x.result._id, label: x.result.name
        }
    })
    const options = [...newOptions, defaultOptions].reverse()


    return (
        <div>
            <Container>
                <ToastContainer />
                <AppBar style={{ marginTop: '103px', background: '#FAFAFA' }}>
                    <Tabs value={value} onChange={handleSubMenuChange} style={{ color: 'blue' }} aria-label="simple tabs example">
                        <Tab label="24/7/365" {...a11yProps(2)} />
                        <Tab label="On Demand" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
                <br />
                <br />
                <div style={{ marginTop: '3%', textAlign: 'right' }}>
                    <TextField
                        type="search"
                        variant="outlined"
                        label="Search"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
                <div style={{ marginTop: '50px' }}>
                    <Button display='flex' onClick={handleOpen}>
                        <AddBoxIcon />
                        Add Application

                    </Button>
                    <Button style={{ float: 'right' }} variant="contained" onClick={handleSubOpen}>SubScribe</Button>
                    <br />
                    <br />
                    <div >
                        <GridOnIcon title="Grid View" onClick={() => setTableView(false)} />
                        <TocIcon title="Table view" onClick={() => setTableView(true)} />
                    </div>

                    {!tableView ?
                        <div style={{ backgroundColor: '#efefef' }}>
                            <div style={{ padding: '10px', marginLeft: '35px' }}>
                                <Grid container>

                                    {
                                        !loaderStatus ?
                                            getApplicationList().map((row, index) => {
                                                return (
                                                    <div key={index} style={{ padding: '5px' }}>
                                                        <Card className={classes.root} style={{ width: '275px', }}>
                                                            <CardContent>
                                                                <Typography title={row.result.name} gutterBottom variant="h5" component="h2" style={{
                                                                    'whiteSpace': 'nowrap',
                                                                    'overflow': 'hidden',
                                                                    'textOverflow': 'ellipsis'
                                                                }}>
                                                                    {row.result.name}
                                                                    <a href={(/https:/.test(row.result.url)) || (/http:/.test(row.result.url)) ? row.result.url : `http://${row.result.url}`} without="true" rel="noopener noreferrer" target="_blank"><OpenInNewIcon /></a>

                                                                </Typography>
                                                            </CardContent>
                                                            <CardActions>
                                                                <Typography variant="caption" display="block" gutterBottom style={{
                                                                    overflow: 'auto'
                                                                }}>
                                                                    <label style={{ cursor: 'pointer' }} onClick={() => handleViewOpen(row.result.name, row.result.url, row.result.applicationPath, row.result.sourceIp, row.result.sourcePassword, row.result.sourceUser, row.result.applicationStack, row.result._id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                                                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg></label>
                                                                    {row.result.applicationStack ? ` ${row.result.applicationStack}` : ' --'}

                                                                </Typography>
                                                            </CardActions>
                                                            <CardActions>
                                                                {
                                                                    row.responseCode === 200 ? <Button variant="contained" size="small" style={{ backgroundColor: '#94e09d' }} className={classes.margin}>
                                                                        Up
                                                                    </Button>
                                                                        :
                                                                        <Button title={`Down: ${row.responseCode}`} variant="contained" size="small" style={{
                                                                            'whiteSpace': 'nowrap',
                                                                            'overflow': 'hidden',
                                                                            'textOverflow': 'ellipsis',
                                                                            backgroundColor: '#dd9696'
                                                                        }} className={classes.margin}>
                                                                            Down: {row.responseCode}
                                                                        </Button>
                                                                }
                                                                <Button variant="contained" size="small" onClick={() => handleMoreInfo(row.result._id, row.result.name)} className={classes.margin}>
                                                                    History
                                                                </Button>
                                                                <EditIcon onClick={() => handleEdit(row.result.name, row.result.url, row.result.applicationPath, row.result.sourceIp, row.result.sourcePassword, row.result.sourceUser, row.result.applicationStack, row.result._id)} style={{ cursor: 'pointer' }} />
                                                            </CardActions>
                                                        </Card>

                                                    </div>
                                                )
                                            })
                                            :
                                            "Loading..."
                                    }
                                </Grid>
                            </div>
                        </div>
                        :
                        <div>
                            <center style={{ border: 'solid', padding: '10px', margin: '10px' }}>
                                <table id="rowsTable" className="table table-striped table-bordered" >
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>URL</th>
                                            <th>Stack</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            getApplicationList().map((row, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{row.result.name}

                                                            <label style={{ cursor: 'pointer', marginLeft: '5px' }} onClick={() => handleViewOpen(row.result.name, row.result.url, row.result.applicationPath, row.result.sourceIp, row.result.sourcePassword, row.result.sourceUser, row.result.applicationStack, row.result._id)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                            </svg></label>
                                                        </td>
                                                        <td><a href={(/https:/.test(row.result.url)) || (/http:/.test(row.result.url)) ? row.result.url : `http://${row.result.url}`} without rel="noopener noreferrer" target="_blank">{row.result.url} <OpenInNewIcon /></a></td>
                                                        <td>
                                                            {row.result.applicationStack}
                                                        </td>
                                                        <td>{
                                                            (row.responseCode === 200 ? <Button variant="contained" size="small" style={{ backgroundColor: '#94e09d' }} className={classes.margin}>
                                                                Up
                                                            </Button>
                                                                :
                                                                <Button variant="contained" size="small" style={{ backgroundColor: '#dd9696' }} className={classes.margin}>
                                                                    Down: {row.responseCode}
                                                                </Button>)}</td>
                                                        <td>
                                                            < div >
                                                                <Button variant="contained" style={{ marginRight: '5px' }} size="small" onClick={() => handleMoreInfo(row.result._id, row.result.name)} className={classes.margin}>
                                                                    Details
                                                                </Button>
                                                                <EditIcon onClick={() => handleEdit(row.result.name, row.result.url, row.result.applicationPath, row.result.sourceIp, row.result.sourcePassword, row.result.sourceUser, row.result.applicationStack, row.result._id)} style={{ cursor: 'pointer' }} />

                                                            </div >
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                        }

                                    </tbody>

                                </table>
                            </center>
                        </div>

                    }
                    {
                        getApplicationList().length === 0 &&
                        <div style={{ textAlign: 'center' }}>
                            No Data Found
                        </div>
                    }
                    {

                    }

                    <br /><br />

                    {/*Histoy Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        // className={classes.modal}
                        open={showMoreTable}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={showMoreTable}>
                            <div style={{
                                position: 'absolute',
                                height: '550px',
                                marginTop: '100px',
                                marginLeft: '10%',
                                width: '80%',
                                backgroundColor: 'white'
                            }}>
                                <div>
                                    <button className="btn" style={{ float: 'right', margin: '10px', paddding: '10px' }} type="button" onClick={() => setShowMoreTable(false)}>x</button>
                                </div>
                                <div style={{ marginTop: '50px', overflow: 'auto', height: '500px', marginLeft: '3%', paddingBottom: '10px' }}>
                                    {
                                        !loaderStatus ?
                                            <div>
                                                <div style={{ marginLeft: '91%', marginBottom: '10px' }}>
                                                    <CSVLink
                                                        data={moreInfoObjLogs}
                                                        filename={`${appName}.csv`}
                                                        className="btn btn-sm btn-info"
                                                        target="_blank"
                                                    >
                                                        Export to CSV
                                                    </CSVLink>
                                                </div>
                                                <center style={{ border: 'solid', padding: '10px', margin: '10px' }}>

                                                    <table id="serverTable" className="table table-striped table-bordered" >
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Last Updated</th>
                                                                <th>Status</th>
                                                                <th>logs</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {

                                                                applicationLogs && applicationLogs.map((row, i) => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>{row.name}</td>
                                                                            <td>{moment(row.createdAt.toLocaleString('en-US', { timeZone: localTimeZone })).format('LLL')}</td>
                                                                            <td>
                                                                                {
                                                                                    (row.status === "true" ? <Button variant="contained" size="small" style={{ backgroundColor: '#94e09d' }} className={classes.margin}>
                                                                                        Up
                                                                                    </Button>
                                                                                        :
                                                                                        <Button variant="contained" size="small" style={{ backgroundColor: '#dd9696' }} className={classes.margin}>
                                                                                            Down
                                                                                        </Button>)}</td>
                                                                            <td style={{ maxWidth: '400px', whiteSpace: 'pre-wrap', overflow: 'auto' }}>{row.status === "false" ? row.log : "--"}</td>
                                                                        </tr>
                                                                    )
                                                                })

                                                            }

                                                        </tbody>

                                                    </table>
                                                </center>

                                            </div>
                                            :
                                            <center style={{ marginTop: '200px' }}>
                                                Loading...
                                            </center>

                                    }

                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    {/* Add Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.paper}>
                                <form onSubmit={e => handleSubmit(e)} noValidate autoComplete="off" className={classes.space}>
                                    <div>
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" label="Application Name*" name="appName" onChange={e => handleChange(e)} color="primary" />
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" label="Application URL*" name="appUrl" onChange={e => handleChange(e)} color="primary" />
                                    </div>
                                    <div>
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" label="Application Path" name="applicationPath" onChange={e => handleChange(e)} color="primary" />
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" label="Source Ip" name="sourceIp" onChange={e => handleChange(e)} color="primary" />
                                    </div>
                                    <div>
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" label="Application Stack" name="applicationStack" onChange={e => handleChange(e)} color="primary" />
                                    </div>
                                    {/* <div>
                                        <TextField variant="filled" className="mr-3" label="Source User" name="sourceUser" onChange={e => handleChange(e)} color="primary" />
                                        <TextField variant="filled" label="Source Password" name="sourcePassword" onChange={e => handleChange(e)} color="primary" />
                                    </div> */}
                                    <div>
                                        <label>24/7/365</label>
                                        <Checkbox name="onDemand" onClick={() => setOnDemand(false)} />
                                        <label>On Demand</label>
                                        <Checkbox name="onDemand" onClick={() => setOnDemand(true)} />
                                    </div>
                                    <div className={classes.space}>
                                        <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Add"}</Button>
                                        {
                                            !loaderStatus ?
                                                <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                                :
                                                null
                                        }
                                    </div>

                                </form>
                            </div>
                        </Fade>
                    </Modal>
                    {/* update Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openEdit}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openEdit}>
                            <div className={classes.paper}>
                                <form onSubmit={e => handleUpdate(e)} noValidate autoComplete="off" className={classes.space}>
                                    <div>
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" value={update.appName} name="appName" onChange={e => handleChangeUpdate(e)} color="primary" />
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" value={update.appUrl} name="appUrl" onChange={e => handleChangeUpdate(e)} color="primary" />

                                    </div>
                                    <div>
                                        <TextField variant="filled" value={update.applicationPath} className="col-12 mt-1 mb-2" label="Application Path" name="applicationPath" onChange={e => handleChangeUpdate(e)} color="primary" />
                                        <TextField variant="filled" value={update.sourceIp} className="col-12 mt-1 mb-2" label="Source Ip" name="sourceIp" onChange={e => handleChangeUpdate(e)} color="primary" />
                                    </div>
                                    <div>
                                        <TextField variant="filled" value={update.applicationStack} className="col-12 mt-1 mb-2" label="Application Stack" name="applicationStack" onChange={e => handleChangeUpdate(e)} color="primary" />
                                    </div>
                                    {/* <div>
                                        <TextField variant="filled" value={update.sourceUser} className="mr-3" label="Source User" name="sourceUser" onChange={e => handleChangeUpdate(e)} color="primary" />
                                        <TextField variant="filled" value={update.sourcePassword} label="Source Password" name="sourcePassword" onChange={e => handleChangeUpdate(e)} color="primary" />
                                    </div> */}
                                    <div>
                                        <label>24/7/365</label>
                                        <Checkbox name="onDemand" onClick={() => setOnDemand(false)} />
                                        <label>On Demand</label>
                                        <Checkbox name="onDemand" onClick={() => setOnDemand(true)} />
                                    </div>
                                    <div className={classes.space}>
                                        <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Update"}</Button>
                                        <Button variant="contained" type="button" onClick={() => handleDelete()} color="secondary" disabled={loaderStatus ? true : false}> Remove</Button>

                                        {
                                            !loaderStatus ?
                                                <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                                :
                                                null
                                        }

                                    </div>

                                </form>
                            </div>
                        </Fade>
                    </Modal>
                    {/* subscription Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={subOpen}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={subOpen}>
                            <div className={classes.paper}>
                                <form onSubmit={e => handleSubScribe(e)} noValidate autoComplete="off" className={classes.space}>
                                    <Select
                                        styles={customSelectStyle}
                                        options={options}
                                        placeholder="Select Application"
                                        onChange={e => setSelectedApp(e)}
                                    />
                                    <TextField style={{ zIndex: 1 }} variant="filled" name="email" label="Email" onChange={e => setEmail(e.target.value)} color="primary" required />
                                    <div className={classes.space}>
                                        <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Add"}</Button>
                                        {
                                            !loaderStatus ?
                                                <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                                :
                                                null
                                        }
                                    </div>

                                </form>
                            </div>
                        </Fade>
                    </Modal>
                    {/* view Modal */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openView}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openView}>
                            <div className={classes.paper}>
                                <form onSubmit={e => handleUpdate(e)} noValidate autoComplete="off" className={classes.space}>
                                    <div>
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" value={update.appName} name="appName" onChange={e => handleChangeUpdate(e)} color="primary" readOnly />
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" value={update.appUrl} name="appUrl" onChange={e => handleChangeUpdate(e)} color="primary" readOnly />

                                    </div>
                                    <div>
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" value={update.applicationPath} label="Application Path" name="applicationPath" onChange={e => handleChangeUpdate(e)} color="primary" readOnly />
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" value={update.sourceIp} label="Source Ip" name="sourceIp" onChange={e => handleChangeUpdate(e)} color="primary" readOnly />
                                    </div>
                                    <div>
                                        <TextField variant="filled" className="col-12 mt-1 mb-2" value={update.applicationStack} label="Application Stack" name="applicationStack" onChange={e => handleChangeUpdate(e)} color="primary" readOnly />
                                    </div>
                                    {/* <div>
                                        <TextField variant="filled" value={update.sourceUser} className="mr-3" label="Source User" name="sourceUser" onChange={e => handleChangeUpdate(e)} color="primary" />
                                        <TextField variant="filled" value={update.sourcePassword} label="Source Password" name="sourcePassword" onChange={e => handleChangeUpdate(e)} color="primary" />
                                    </div> */}
                                    <div className={classes.space}>

                                        {
                                            !loaderStatus ?
                                                <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                                :
                                                null
                                        }

                                    </div>
                                </form>
                            </div>
                        </Fade>
                    </Modal>
                </div >
            </Container >
        </div >
    );
}