import React, { useState, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { TextField, Button, Container, Switch } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import API from '../Common/Network/API';
import { makeStyles } from '@material-ui/core/styles';
import '../Common/Css/style.css'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux'
import { isLoading, isLoaded } from '../Common/Actions/loading'
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const label = { inputProps: { 'aria-label': 'Switch demo' } };



const useStyles = makeStyles((theme) => ({
    modalForm: {
        padding: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    space: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export function Data(props) {

    // const [message, setMessage] = useState()

    // console.log(props)
    // if (props.type === 'server') {
    //     props.url.then(res => {
    //         setMessage(res)
    //     })
    // }


    return (
        <div>
            {
                props.type === 'server' ?
                    <>
                        {
                            props.status === 'On' ?
                                <div style={{ backgroundColor: '#95DEB0', borderRadius: '5px', width: '100px', textAlign: 'center' }}>
                                    <p style={{ padding: '5px' }}>{props.status}</p>
                                </div>
                                :
                                <div style={{ backgroundColor: '#dd9696', borderRadius: '5px', width: '100px', textAlign: 'center' }}>
                                    <p style={{ padding: '5px' }}>{props.status}</p>
                                </div>
                        }

                    </>
                    :

                props.status ?
                    <div style={{ backgroundColor: '#95DEB0', borderRadius: '5px', width: '100px', textAlign: 'center' }}>
                        <p style={{ padding: '5px' }}>UP</p>
                    </div>
                    :
                    <div style={{ backgroundColor: '#dd9696', borderRadius: '5px', width: '100px', textAlign: 'center' }}>
                        <p style={{ padding: '5px' }}>Down</p>
                    </div>

            }
        </div>
    )
}

export default function Applications() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [data, setData] = useState({})
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)
    const [rows, setRows] = useState([])
    const [openEdit, setOpenEdit] = useState(false)
    const [update, setUpdate] = useState({})
    const [subOpen, setSubOpen] = useState(false)
    const [email, setEmail] = useState()
    const [loader, setLoader] = useState(false)
    const [appName, setAppName] = useState(null)


    const dispatch = useDispatch()

    const loaderStatus = useSelector(state => state.load.status)


    useEffect(() => {
        if (rows.length > 0) {
            $(document).ready(function () {
                window.$('#serverTable').DataTable({
                    bDestroy: true,
                    "order": [[0, 'desc']]
                });
            });
        }
    }, [rows, loaderStatus])


    useEffect(() => {
        getServerData()
    }, [])


    async function getServerData() {
        dispatch(isLoading())
        setRows([])
        await API.get("/serverApi/get", {}, {}, true)
            .then(result => {
                setRows(result)
                dispatch(isLoaded())
            })
            .catch(err => {
                console.log(err)
                dispatch(isLoaded())
            })
        dispatch(isLoaded())
    }


    const handleOpen = () => {
        setOpen(true);
    };

    const handleSubOpen = () => {
        setSubOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEdit(false);
        setSubOpen(false)
    };
    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeUpdate = (e) => {
        setUpdate({
            ...update,
            [e.target.name]: e.target.value
        })
    }
    const handleEdit = (name, url, type, id) => {
        setOpenEdit(true)
        setUpdate({
            id: id,
            serverName: name,
            serverUrl: url,
            type: type
        })
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!data.serverName || !data.serverUrl || !data.type) {
            toast.info("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/serverApi/add", {
                    name: data.serverName,
                    url: data.serverUrl,
                    type: data.type
                }, {}, true);
                handleClose()
                getServerData()
                toast.info(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!update.serverName || !update.serverUrl || !update.type) {
            toast.info("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/serverApi/update", {
                    linkId: update.id,
                    name: update.serverName,
                    url: update.serverUrl,
                    type: update.type
                }, {}, true);
                handleClose()
                getServerData()
                toast.info(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }

    const handleDelete = async () => {
        if (!update.id) {
            setError("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/serverApi/remove", {
                    id: update.id,
                }, {}, true);
                handleClose()
                getServerData()
                toast.info(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }

    const handleSubScribe = async (e) => {
        e.preventDefault();
        if (!email) {
            setError("Please Fill Required Fields")
        } else {
            setError("")
            dispatch(isLoading())
            try {
                const response = await API.post("/authApi/subScription", {
                    email: email,
                }, {}, true);
                handleClose()
                setSuccess(response.message)
                dispatch(isLoaded())
            }
            catch (error) {
                setError(error.message)
            }
            dispatch(isLoaded())
        }
    }

    const handlePower = async (data, power) => {
        setLoader(true)
        setAppName(data.name)
        try {
            const response = await API.post("/serverApi/handlePower", {
                data: data,
                power: power
            }, {}, true);
            setLoader(false)
            toast.info(response.message)
            getServerData()

        }
        catch (error) {
            setError(error.message)
        }
        setLoader(false)

    }


    return (
        <Container>
            <ToastContainer />
            <div style={{ marginTop: '12%' }}>
                <Button display='flex' onClick={handleOpen}>
                    <AddBoxIcon />
                    Add Server

                </Button>
                <Button style={{ float: 'right' }} variant="contained" onClick={handleSubOpen}>SubScribe</Button>
                <div>
                    {success ?
                        <Alert severity="success">{success}</Alert>
                        :
                        null}
                </div>
                <br />
                <div>
                    {
                        loaderStatus && <center>Loading...</center>
                    }
                    {
                        !loaderStatus &&
                        <div>
                            {
                                rows.length > 0 &&
                                <table id="serverTable" className="table table-striped table-bordered" >
                                    <thead>
                                        <tr>
                                            <th style={{ display: 'none' }}>id</th>
                                            <th>Name</th>
                                            <th>IP Address</th>
                                            <th>Type</th>
                                            <th>Status</th>
                                            <th>Edit</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            rows?.map(row => {
                                                return (
                                                    <tr key={row?.result?._id}>
                                                        <td style={{ display: 'none' }}>{row?.result?._id}</td>
                                                        <td>{row?.result?.name}</td>
                                                        <td>{row?.result?.url} <a href={(/https:/.test(row?.result?.url)) || (/http:/.test(row?.result?.url)) ? row?.result?.url : `http://${row?.result?.url}`} without="true" rel="noopener noreferrer" target="_blank"><OpenInNewIcon /></a></td>
                                                        <td>{row?.result?.type}</td>
                                                        <td>
                                                            <Data type={row?.result?.type} status={row?.pingStatus} />
                                                        </td>
                                                        <td>
                                                            <EditIcon onClick={() => handleEdit(row.result?.name, row.result?.url, row?.result?.type, row.result?._id)} /></td>
                                                        {
                                                            row?.result?.type === 'server' ?
                                                                <td>Power {row.result?.powerStatus === true ? "on" : "off"} <Switch {...label} defaultChecked={row.result?.powerStatus} color="primary" onClick={(e) => handlePower(row?.result, e.target.checked)} /> {appName === row.result?.name && loader ? <img style={{ width: '20px' }} src='./assets/img/loader.gif' alt="Loading..." /> : ""}  </td>
                                                                :
                                                                <td></td>
                                                        }
                                                    </tr>
                                                )
                                            })

                                        }

                                    </tbody>
                                </table>
                            }
                        </div>
                    }

                </div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <form onSubmit={e => handleSubmit(e)} noValidate autoComplete="off" className={classes.space}>
                                <TextField variant="filled" label="Application Name*" name="serverName" onChange={e => handleChange(e)} color="primary" />
                                <TextField variant="filled" label="Application URL*" name="serverUrl" onChange={e => handleChange(e)} color="primary" />
                                <br />
                                <select className='form-control' name="type" onChange={e => handleChange(e)} color="primary">
                                    <option value="">Select One</option>
                                    <option value="vm">Virtual Machine</option>
                                    <option value="server">Server</option>
                                </select>
                                <div className={classes.space}>
                                    <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" alt="" width="20px" /> : "Add"}</Button>
                                    {
                                        !loaderStatus ?
                                            <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                            :
                                            null
                                    }
                                </div>
                                {/* {
                                    error ?
                                        <Alert severity="warning">{error}</Alert>
                                        :
                                        ""
                                } */}
                            </form>
                        </div>
                    </Fade>
                </Modal>
                {/* update Modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openEdit}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openEdit}>
                        <div className={classes.paper}>
                            <form onSubmit={e => handleUpdate(e)} noValidate autoComplete="off" className={classes.space}>
                                <TextField variant="filled" value={update.serverName} name="serverName" onChange={e => handleChangeUpdate(e)} color="primary" />
                                <TextField variant="filled" value={update.serverUrl} name="serverUrl" onChange={e => handleChangeUpdate(e)} color="primary" />
                                <br />
                                <select className='form-control' value={update.type} name="type" onChange={e => handleChangeUpdate(e)} color="primary">
                                    <option value="">Select One</option>
                                    <option value="vm">Virtual Machine</option>
                                    <option value="server">Server</option>
                                </select>
                                <div className={classes.space}>
                                    <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" width="20px" alt="" /> : "Update"}</Button>
                                    <Button variant="contained" type="button" onClick={() => handleDelete()} color="secondary" disabled={loaderStatus ? true : false}> Remove</Button>
                                    {
                                        !loaderStatus ?
                                            <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                            :
                                            null
                                    }
                                </div>
                                {/* {
                                    error ?
                                        <Alert severity="warning">{error}</Alert>
                                        :
                                        ""
                                } */}
                            </form>
                        </div>
                    </Fade>
                </Modal>
                {/* subscription Modal */}
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={subOpen}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={subOpen}>
                        <div className={classes.paper}>
                            <form onSubmit={e => handleSubScribe(e)} noValidate autoComplete="off" className={classes.space}>
                                <TextField variant="filled" name="email" label="Email*" onChange={e => setEmail(e.target.value)} color="primary" />
                                <div className={classes.space}>
                                    <Button variant="contained" type="submit" color="primary" disabled={loaderStatus ? true : false}> {loaderStatus ? <img src="./assets/img/loader.gif" width="20px" alt="" /> : "Add"}</Button>
                                    {
                                        !loaderStatus ?
                                            <Button variant="contained" color="secondary" onClick={() => handleClose()}>Cancel</Button>
                                            :
                                            null
                                    }
                                </div>
                                {
                                    error ?
                                        <Alert severity="warning">{error}</Alert>
                                        :
                                        ""
                                }
                            </form>
                        </div>
                    </Fade>
                </Modal>

            </div>
        </Container>
    );
}